@import url('http://fonts.googleapis.com/css?family=Lato:400,700');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      @apply scroll-smooth;
    }
}

*{
	font-family: 'Lato', sans-serif;
}