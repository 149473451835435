
#headerList{
    padding-top: 55vh;
}

#feildMargin{
    margin-top: 2vh;
}

.smallTextMargin{
    margin-top: 0.30vh;
}

#bottomGray{
    padding-bottom: 35vh;
}